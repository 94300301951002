@import "../../styles/variables";
@import "../../styles/mixins";

.page {
  display: flex;
  flex-direction: column;

  min-height: 100%;

  .header {
    flex: none;

    padding: 0 $gap;

    height: auto;

    color: #fff;
    background: rgb(0,21,41);
    background: linear-gradient(90deg, rgba(0,21,41,1) 0%, rgba(0,21,41,0.9) 50%, rgba(0,21,41,0.8) 100%); }

  .content {
    flex: 1 0 auto;

    padding: $gap;

    &:after {
      content: '\00a0';
      display: block;
      margin-top: var(--space);
      height: 0;
      visibility: hidden; } }

  .footer {
    flex: none;

    display: flex;
    justify-content: flex-end;

    padding: 5px $gap;

    color: #fff;
    font-size: 12px;
    background: rgb(0,21,41);
    background: linear-gradient(90deg, rgba(0,21,41,1) 0%, rgba(0,21,41,0.9) 50%, rgba(0,21,41,0.8) 100%); } }
