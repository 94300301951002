.blockTime {
  margin-bottom: 4px;
  .time {
    font-size: 12px;
    color: #6c6c6c; }
  .badge {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 6px;
    font-size: 12px;
    .badgeCircle {
      border-radius: 50%;
      width: 6px;
      height: 6px;
      margin-right: 6px; } } }
