@import "variables";
@import "mixins";
@import "button";

html, body, .root {
  @include fit;
  @include nogap; }

.fit {
  @include fit; }

.centered {
  @include centered; }

.space-between {
  @include space-between; }


/* Ant Design */

.ant-typography-copy {
  color: inherit; }

.ant-badge-status-dot {
  width: 1em;
  height: 1em; }

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  margin: 1px;
  border: 2px solid #f0f0f0; }

.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 12px 0; }

.ant-picker-content th {
  font-weight: bold; }
