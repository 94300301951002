.HeaderTitle {
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 16px; }

.Block {
  height: 100%;
  align-items: center;
  b, i {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
    display: inline-block;
    line-height: 16.5px; } }

