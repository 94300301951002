@mixin fit {
  width: 100%;
  height: 100%; }

@mixin centered {
  @include fit;

  align-items: center;
  display: flex;
  justify-content: center; }

@mixin space-between {
  display: flex;
  justify-content: space-between;
  flex: 1; }

@mixin nogap {
  margin: 0;
  padding: 0; }

