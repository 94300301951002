.filedVote {
  cursor: pointer;
  width: 90%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 8px;
  &Data {
    width: 100%;
    display: flex;
    align-items: center;
    > label {
      margin-left: 10px; }
    &Band {
      width: 90%;
      height: 30px;
      background: rgba(132, 132, 132, 0.13);
      border-radius: 4px;
      position: relative;
      &Scale {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: #1990ff47;
        z-index: 10;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        > span {
          font-size: 12px;
          margin-left: 20px; } } }
    > span {
      font-size: 12px;
      position: relative;
      top: -2px; } }
  &Info {
    > span:first-child {
      margin-right: 8px; }
    &Name:not(:last-child):after {
      content: ', '; } }
  > span {
    font-size: 12px; } }
